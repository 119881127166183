<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">motions enacted</div>
      </v-row>
      <v-row class="">
        <div class="">
          An ordinance is a legislative enactment of general, permanent
          character having the equivalent status of a statute. Ordinances
          constitute the subject matter of municipal law. The following lists
          those ordinances enacted by the City Council and approved by the Mayor
          during the review period
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>

        <v-autocomplete
          prepend-icon="mdi-calendar-clock-outline"
          label="Period"
          :items="periodItems"
          outlined
        ></v-autocomplete>
        <v-autocomplete
          prepend-icon="mdi-account"
          label="Author"
          :items="authorItems"
          outlined
        ></v-autocomplete>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="motionsEnacted"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date"
              @click:row="pdfDialog = true"
            >
              <template v-slot:[`item.passage_date`]="{ item }">
                {{ item.passage_date | moment("Do MMMM YYYY") }}
              </template>
              <template v-slot:[`item.introduction_date`]="{ item }">
                {{ item.introduction_date | moment("Do MMMM YYYY") }}
              </template>
              <template v-slot:[`item.committee`]="{ item }">
                {{ item.committee == null ? "None" : item.committee.name }}
              </template>
            </v-data-table>

            <!-- <v-dialog v-model="pdfDialog">
              <pdf-viewer />
            </v-dialog> -->
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
// import PdfViewer from "../PdfViewer.vue";
import { mapState, mapActions } from "pinia";
import { motionStore } from "@/stores/motions";

export default {
  // components: { PdfViewer },
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      pdfDialog: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      periodItems: [],
      authorItems: [],
      headers: [
        {
          text: "ordinance number",
          value: "ordinance_no",
          class: "text-uppercase black--text",
        },
        {
          text: "passage date",
          value: "passage_date",
          class: "text-uppercase black--text",
        },
        {
          text: "title",
          value: "title",
          class: "text-uppercase black--text",
        },
        {
          text: "description",
          value: "description",
          width: "20%",
          class: "text-uppercase black--text",
        },
        {
          text: "introduction date",
          value: "introduction_date",
          class: "text-uppercase black--text",
        },
        {
          text: "commitee",
          value: "committee",
          class: "text-uppercase black--text",
        },
        {
          text: "author",
          value: "author",
          class: "text-uppercase black--text",
        },
        // {
        //   text: "file number",
        //   value: "file",
        //   class: "text-uppercase black--text",
        // },
        {
          text: "enacted date",
          value: "enacted_date",
          class: "text-uppercase black--text",
        },
      ],
    };
  },
  computed: {
    ...mapState(motionStore, ["motionsEnacted"]),
  },
  methods: {
    ...mapActions(motionStore, ["getMotionsEnacted"]),
  },
  created() {
    this.getMotionsEnacted();
  },
};
</script>

<style></style>
